import React from 'react'
import { graphql } from 'gatsby'
import { Blog as BlogTemplate } from '../components/templates'

const Blog: React.FC<Props> = ({ data, location }) => {
  const blogPost = data?.contentfulBlogPost

  return (
    <BlogTemplate
      title={blogPost.title}
      publishDate={blogPost.publishDate}
      gatsbyImageData={blogPost?.heroImage?.gatsbyImageData}
      description={blogPost.description.description}
      body={blogPost.body.body}
      location={location}
    />
  )
}

export default Blog

export const query = graphql`
  query ArticleQuery($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MMM DD, YYYY")
      heroImage {
        gatsbyImageData(layout: CONSTRAINED, height: 720)
      }
      description {
        description
      }
      body {
        body
      }
    }
  }
`

interface Props {
  data: {
    contentfulBlogPost: {
      title: string
      publishDate: string
      heroImage: {
        gatsbyImageData: any
      }
      description: {
        description: string
      }
      body: {
        body: string
      }
    }
  }
  location: any
}
